import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "22 年度新卒研修に取り入れた QA 講座について QA エンジニアに語ってもらいました",
  "date": "2022-08-31T13:35:42.000Z",
  "slug": "entry/2022/08/31/223542",
  "tags": ["medley"],
  "hero": "./2022_08_31.png",
  "heroAlt": "Title"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`はじめに`}</h1>
    <p>{`みなさん、こんにちは。エンジニアの山田です。`}</p>
    <p>{`今年も新卒入社で 6 人のメンバーがエンジニアとして入社しており、現在も絶賛新卒研修中となっています。毎年、新卒研修はプロセスやコンテンツを見直しているのですが、今年度は初めて Quality Assurance(以下 QA)についての講座を取り入れるようにしました。`}</p>
    <p>{`今回は講師をお願いした QA エンジニアお二人にどのようなことを念頭に講義を実施したのかをインタビューしてみました。`}</p>
    <h1>{`今年から QA 講座を新卒研修のカリキュラムとして取り入れた理由について`}</h1>
    <p>{`インタビュー本編をお届けする前に、今回 QA 講座を新卒研修に取り入れた理由について研修企画担当の 1 人としてご説明します。`}</p>
    <p>{`QA 講座を新卒研修のカリキュラムに取り入れたいと思ったきっかけは、昨年の「開発実践研修」でアプリケーション開発の一環としてテスト設計も含めて実施していましたが、メンターとしてレビューをしている際「`}<strong parentName="p">{`せっかくメドレーには QA のスペシャリストがいるんだから、今回のレビューにも加わってもらえば良かった`}</strong>{`…」と感じたことでした。`}</p>
    <p>{`今年はメンターではなく、新卒研修の企画・運営を主に担当することになり、昨年よりもさらに研修の質を上げるためにはどうするかを考えた時、上記のきっかけもあり
QA エンジニアの皆さんには「開発実践研修」のテスト設計レビューを含め、座学による講義もお願いしたいと思うようになりました。`}</p>
    <p>{`講義をお願いしたいと思った理由として大きく 3 つあります。`}</p>
    <h2>{`1. QA の経験不足を補う`}</h2>
    <p>{`全体的な傾向として弊社の新卒エンジニアは、インターンや学校の活動などで開発経験は相応にあるのですが、テスト設計をはじめとする QA を特に力を入れてやった経験が浅いという事実があります。その上でこれから先メドレーでプロダクト開発をする上では、テストや QA などについて自走して考え実施することができるようにしたかったため。`}</p>
    <h2>{`2. QA に対する認識を揃え、意識を高める`}</h2>
    <p>{`「QA とは何ぞや?」という話を専門性を持った QA エンジニアから新卒エンジニアに伝えてもらいプロダクトの QA に対して意識を高めてもらいたかったため。`}</p>
    <h2>{`3. 社内勉強会の内容がとても為になると感じた`}</h2>
    <p>{`弊社では、TechLunch という社内勉強会を開催していますが、その中で今回インタビューした QA エンジニアお二人の発表内容が大変良かったので、その内容をぜひ新卒エンジニアにも伝えてほしいと感じたため。`}</p>
    <p>{`このような理由により、今年度からは QA 講座を研修の一部として開くことになりました。`}</p>
    <p>{`さて、前置きが長くなりましたがそんな QA 講座を担当してくださった QA エンジニアのお二人へのインタビューをご覧ください。`}</p>
    <h1>{`インタビュイー紹介`}</h1>
    <h2>{`上村さん`}</h2>
    <p>{`医療プラットフォーム第一本部 プロダクト開発室
第一開発グループに所属。クラウド診療支援システム `}<a parentName="p" {...{
        "href": "https://clinics-cloud.com/"
      }}>{`CLINICS`}</a>{`
の医療機関側システム（カルテ、オンライン診療など）の品質向上活動を担当。`}</p>
    <h2>{`米山さん`}</h2>
    <p>{`医療プラットフォーム第一本部 プロダクト開発室
第一開発グループに所属。オンライン診療・服薬指導アプリ `}<a parentName="p" {...{
        "href": "https://clinics-app.com/"
      }}>{`CLINICS`}</a>{` のアプリ・
Web ・基盤を扱う開発チームで QA プロセス全般を担当。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/ee8aadfcea8b12bbd5733c69986bf7ed/eea4a/0804_001.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAEDBAX/xAAWAQEBAQAAAAAAAAAAAAAAAAABAAL/2gAMAwEAAhADEAAAAbpcTDtLkf/EABsQAAMAAgMAAAAAAAAAAAAAAAECAwAEERMx/9oACAEBAAEFApuWyrmajZbi+N5BR1f/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAgEBPwGI/8QAGRAAAgMBAAAAAAAAAAAAAAAAAAEQESFR/9oACAEBAAY/Ai8ZyViP/8QAHRABAAICAgMAAAAAAAAAAAAAAQARITFBUWGRsf/aAAgBAQABPyFx09xCEjeZWVQOKhoowE2nk+RLvqn/2gAMAwEAAgADAAAAEJjf/8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQMBAT8Qp//EABgRAAIDAAAAAAAAAAAAAAAAAAAhATFB/9oACAECAQE/EF0lrP/EABwQAQADAAIDAAAAAAAAAAAAAAEAESExYXGBkf/aAAgBAQABPxCi0ChKUeCDsR0LfjElxgBc9wq0BgYGRKxy6u4eSU1Ts//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "0804 001",
            "title": "0804 001",
            "src": "/static/ee8aadfcea8b12bbd5733c69986bf7ed/e5166/0804_001.jpg",
            "srcSet": ["/static/ee8aadfcea8b12bbd5733c69986bf7ed/f93b5/0804_001.jpg 300w", "/static/ee8aadfcea8b12bbd5733c69986bf7ed/b4294/0804_001.jpg 600w", "/static/ee8aadfcea8b12bbd5733c69986bf7ed/e5166/0804_001.jpg 1200w", "/static/ee8aadfcea8b12bbd5733c69986bf7ed/eea4a/0804_001.jpg 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><em parentName="p">{`左から上村さん・米山さん・筆者`}</em></p>
    <h1>{`QA 講座を担当した QA エンジニアのインタビュー`}</h1>
    <h2>{`チームの雰囲気`}</h2>
    <p><strong parentName="p">{`山田`}</strong>{`: 早速ですが、お二人とも医療プラットフォームで QA
を担当されていますが、所属しているチームの雰囲気はどんな感じなんでしょうか?`}</p>
    <p><strong parentName="p">{`上村`}</strong>{`:
チームというよりもメドレーのエンジニア・デザイナーの皆さんに共通している話かもしれませんが、`}<strong parentName="p">{`セルフマネジメントできている方がとても多い`}</strong>{`と感じます。そういった方達なので視野を広く持っていて、開発の時にメンバー間で落ちそうなボールを自分でさっと拾って担当したりを自然にやっていますね。後は CLINICS の特に電子カルテは開発に必要なドメイン知識が広範囲で、私も入社して 2 年強経ちますが、未だに分かっていない部分などが出てくることもあり、そんな時でも`}<strong parentName="p">{`気軽に周囲のメンバーに聞ける雰囲気`}</strong>{`なのが良いです。`}</p>
    <p><strong parentName="p">{`山田`}</strong>{`: 現在は QA エンジニアとして、仕様策定のフェイズから関わっていることが多いんでしょうか?`}</p>
    <p><strong parentName="p">{`上村`}</strong>{`: 関わり方はプロジェクトや時期により違っています。メインで担当するプロジェクトは最初から入って仕様レビューから関わる場合もありますし、複数のプロジェクトが平行している時は要所でアドバイス的な形を取る場合もあります。現在は開発中の大きいプロジェクトでの活動に振り切っているフェイズになっているところです。`}</p>
    <p><strong parentName="p">{`山田`}</strong>{`: 米山さんのチームの雰囲気などはいかがですか?`}</p>
    <p><strong parentName="p">{`米山`}</strong>{`: 上村さんとは違うチームに所属していますが、似た印象を持っています。一言で言うと「大人が集まっている」チームだと思います。ミーティングなどでも`}<strong parentName="p">{`本質を捉えた議論が多く、皆さん人格者で仕事ができる方々`}</strong>{`だと感じています。今所属してるのが患者さんが使うアプリの開発チームで、Android / iOS / Web と 3 プラットフォームのプロダクト開発をしているのですが、1 人のエンジニアが `}<strong parentName="p">{`3 プラットフォーム全てを実装したりするのはこのチームの特徴の一つ`}</strong>{`かもしれません。他社さんと比較すると珍しい体制ではないかと思います。`}</p>
    <p><strong parentName="p">{`山田`}</strong>{`: なるほど。リソース効率を考えた結果のチーム運営なんですね。`}</p>
    <h2>{`研修のオファーの打診がされた時に考えたこと`}</h2>
    <p><strong parentName="p">{`山田`}</strong>{`: 今回お二人に QA 講座の担当をオファーさせていただいた訳ですが、オファーを受けた時にどのような思いでしたか?`}</p>
    <p><strong parentName="p">{`上村`}</strong>{`: 「ついにこの時が来たか…!」と思いました w`}</p>
    <p><strong parentName="p">{`山田`}</strong>{`: QA の啓蒙をされている上村さんとしては、やはり講師のオファーを待ち望んでいたわけなんですね。`}</p>
    <p><strong parentName="p">{`上村`}</strong>{`: はい、私が入社したのが一昨年の 4 月だったので当時の新卒研修カリキュラムはもう決まっていたと思うのですが、もしその時にオファーが突然あっても大丈夫でした。去年はオファーが無かったので「まだ早いのかな」と少し残念だったのですが、`}<strong parentName="p">{`今年オファーがあって良かった`}</strong>{`です。`}</p>
    <p><strong parentName="p">{`米山`}</strong>{`: メドレーは元々プロダクト品質に対する意識が高いと感じているので、お話をもらった時に違和感はなかったです。こういった意識の高さは、経験豊富なメンバー達が作ってきた開発文化が社内に浸透しているからだと思います。ですので、`}<strong parentName="p">{`入社時にこうした研修を通して目線を合わせるという意味で良い取り組み`}</strong>{`だと考えています。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/c23ad8d57d7ee69c984e51ad784fc8e9/eea4a/0804_005.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAwQA/8QAFgEBAQEAAAAAAAAAAAAAAAAAAgAB/9oADAMBAAIQAxAAAAG0lAbBqNL/xAAcEAEAAgEFAAAAAAAAAAAAAAACAQMEEyIxMjP/2gAIAQEAAQUC04GPQYJso3rg9n6f/8QAFxEBAAMAAAAAAAAAAAAAAAAAAAEhMf/aAAgBAwEBPwGMU//EABYRAQEBAAAAAAAAAAAAAAAAAAABIf/aAAgBAgEBPwGtf//EABsQAAIBBQAAAAAAAAAAAAAAAAABAhESE2Fx/9oACAEBAAY/Asd1R7GSES6f/8QAHBAAAwABBQAAAAAAAAAAAAAAAAERECExQYGh/9oACAEBAAE/IU5hrolk91OemJ6Bn2j/2gAMAwEAAgADAAAAEGzP/8QAFhEBAQEAAAAAAAAAAAAAAAAAAQBB/9oACAEDAQE/EBBWX//EABcRAQEBAQAAAAAAAAAAAAAAAAEAITH/2gAIAQIBAT8QeyDjf//EABwQAQACAwADAAAAAAAAAAAAAAEAESExYYGR8P/aAAgBAQABPxB9XnYxj3ERawuiqlyqa2l3uJBFCjHiY/bUJQp27P/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "0804 005",
            "title": "0804 005",
            "src": "/static/c23ad8d57d7ee69c984e51ad784fc8e9/e5166/0804_005.jpg",
            "srcSet": ["/static/c23ad8d57d7ee69c984e51ad784fc8e9/f93b5/0804_005.jpg 300w", "/static/c23ad8d57d7ee69c984e51ad784fc8e9/b4294/0804_005.jpg 600w", "/static/c23ad8d57d7ee69c984e51ad784fc8e9/e5166/0804_005.jpg 1200w", "/static/c23ad8d57d7ee69c984e51ad784fc8e9/eea4a/0804_005.jpg 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`QA 研修コンテンツの準備`}</h2>
    <p><strong parentName="p">{`山田`}</strong>{`: 今回の研修コンテンツを作る上で、工夫されていた点や意識されたポイントなどは、どのようなものがありましたか?`}</p>
    <p><strong parentName="p">{`上村`}</strong>{`: 準備段階で私のほうで素案を作ってから、直属の上司で研修の責任者でもある田中(医療 PF
CTO)に相談したところ、「品質というのがプロダクトに取ってどれだけ重要なのかを教えてほしい」というオーダーを頂きました。`}</p>
    <p>{`その後に米山さんと、どのように講義を分けるかを話し合い、私は QA の重要性や `}<strong parentName="p">{`QA とは?`}</strong>{`をテーマにテスト技法の演習を通して、まずは「品質」という観点がいかにプロダクトを作る上で大事なのかという大枠を話すことになりました。一方で米山さんはより実務に即した観点から、メドレーでの具体的な取り組みや自動テスト、開発プロセスとの関わり方などを話すことになりました。`}</p>
    <p>{`こんな形で役割分担はスムーズに決まりましたので、まずは研修目的を新卒のスキルレベルから考えて設定しました。研修全体では田中から「メドレーのエンジニアに求めること」の講座があり、内容としてはマインドセットなど抽象度が高めの内容になります。その後に具体的な「開発基礎研修」が続くので、ちょうどその中間的な抽象度になるように今回の講座は作るように意識しました。`}</p>
    <p>{`私が考えるカリキュラムで大事にしているのは、`}<strong parentName="p">{`講義中にも手を動かしたり、自分でも考えてもらいながら参加してもらう`}</strong>{`所なので、そこも意識しています。`}</p>
    <p><strong parentName="p">{`山田`}</strong>{`: ちょうど良い抽象度で QA の重要性を全般的に分かるようにまとめていったんですね。米山さんは、どんな点が挙げられますか?`}</p>
    <p><strong parentName="p">{`米山`}</strong>{`: `}<strong parentName="p">{`来年以降でも使えるようなコンテンツにしたい`}</strong>{`と考えていました。前職でも非 QA エンジニア向けの研修があったのですが、講義の感想で「もっと現場での実際の話なども聞いてみたかった」というような意見があるのを見ていたので、私のコンテンツでは抽象度が高い話だけではなく、実際のプロジェクトでの事例なども盛り込むようにしました。`}</p>
    <p>{`また、 `}<strong parentName="p">{`テスト自動化`}</strong>{` なども人によって考え方も様々だったりするので、テストピラミッドの話なども含めて現実的な考え方や活用方法などを講座には入れています。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/e6211bf1fd15e936152c7a51766bcfad/eea4a/0804_002.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAUBAwb/xAAVAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAABLHC+XPkFn//EABsQAAICAwEAAAAAAAAAAAAAAAECAxIABDEy/9oACAEBAAEFAopGZepdSQozZAtH5//EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAEDAQE/Aaf/xAAWEQEBAQAAAAAAAAAAAAAAAAAAASH/2gAIAQIBAT8BsY//xAAdEAACAgEFAAAAAAAAAAAAAAAAAQIRIRASMVGh/9oACAEBAAY/Anl2OTqosb2+nCFjvT//xAAcEAACAwADAQAAAAAAAAAAAAAAAREhMVFhcYH/2gAIAQEAAT8hgGY45HYUcluoHO22FVSvhmmNnQtvT//aAAwDAQACAAMAAAAQjC//xAAYEQACAwAAAAAAAAAAAAAAAAAAAREhQf/aAAgBAwEBPxBQLen/xAAXEQEBAQEAAAAAAAAAAAAAAAABABEx/9oACAECAQE/EBYwcv/EAB0QAQEAAgIDAQAAAAAAAAAAAAERACExcUFRYaH/2gAIAQEAAT8QG/KhiuwD1JmtuCi1qT2/uaECpEXuecHaziEmV+9ZpzW+8JoD0+Gf/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "0804 002",
            "title": "0804 002",
            "src": "/static/e6211bf1fd15e936152c7a51766bcfad/e5166/0804_002.jpg",
            "srcSet": ["/static/e6211bf1fd15e936152c7a51766bcfad/f93b5/0804_002.jpg 300w", "/static/e6211bf1fd15e936152c7a51766bcfad/b4294/0804_002.jpg 600w", "/static/e6211bf1fd15e936152c7a51766bcfad/e5166/0804_002.jpg 1200w", "/static/e6211bf1fd15e936152c7a51766bcfad/eea4a/0804_002.jpg 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`QA 研修講座について紹介`}</h2>
    <p><strong parentName="p">{`山田`}</strong>{`: そのように工夫をして作っていただいた講座ですが、それぞれどのような内容だったのか教えていただけますか?`}</p>
    <p><strong parentName="p">{`上村`}</strong>{`: 一般的に知られる障害の事例として例えば「`}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Space_Shuttle_Challenger_disaster"
      }}>{`チャレンジャー号爆発事故`}</a>{`」などを挙げて、まず`}<strong parentName="p">{`「品質」がプロダクトに与える影響について知ってもらう`}</strong>{`ようにしました。事業ドメインなどが違う事例をいくつか挙げています。`}</p>
    <p>{`後半では、具体的にどのように「品質を上げること」ができるかという部分にスポットを当てて紹介をしています。`}</p>
    <iframe className="speakerdeck-iframe" frameBorder="0" src="https://speakerdeck.com/player/d0d3369efc7d432a94d6fb48f9fd4a17" title="FY22 新卒研修 Quality Assurance  〜「QA」「品質」「テスト」とは？〜" allowFullScreen="true" mozallowfullscreen="true" webkitallowfullscreen="true" style={{
      "border": "0px",
      "background": "padding-box padding-box rgba(0, 0, 0, 0.1)",
      "margin": "0px",
      "padding": "0px",
      "borderRadius": "6px",
      "boxShadow": "rgba(0, 0, 0, 0.2) 0px 5px 40px",
      "width": "560px",
      "height": "315px"
    }} data-ratio="1.7777777777777777"></iframe>
    <p><em parentName="p">{`上村の発表スライド`}</em></p>
    <p><strong parentName="p">{`米山`}</strong>{`: 私は具体的なメドレーでの品質向上のためにやっている取り組み（ `}<a parentName="p" {...{
        "href": "https://developer.medley.jp/entry/2021/01/15/180126"
      }}>{`Magic Pod 導入の話`}</a>{` など）や、品質とテストに対する考え方などをお話しました。またメドレーのメンバーの良い取り組み事例として`}<strong parentName="p">{`ホットフィックスでも、きちんとテストコードを追加してリリース`}</strong>{`している部分などを、どう考えてこの対応をしたのだろうかというような事も講義内容に入れています。`}</p>
    <h2>{`講座についての手応え`}</h2>
    <p><strong parentName="p">{`山田`}</strong>{`: お話を聞いていると、すごくバランスが取れた講座に仕上がってる印象を持ちました。講義をした手応えとしては、どんなものがありましたか?`}</p>
    <p><strong parentName="p">{`米山`}</strong>{`: 今回の講義はオンライン形式だったこともあり、少し一方通行で終わってしまったかもしれないなと思いました。もう少しカジュアルに質疑応答も含めてインタラクティブに新卒メンバーと交流できたら、もっと良かったかなと思います。(`}<u>{`編注: 今年はコロナ対策として、オンラインで研修を行ないました`}</u>{`)`}</p>
    <p><strong parentName="p">{`上村`}</strong>{`: 途中少し問いかけのタイミングなどもつくったんですけどもね。`}</p>
    <p><strong parentName="p">{`山田`}</strong>{`: 研修の時期的に新卒メンバーの緊張なんかもあったんでしょうね。話は変わりますが、今回 1 講座をお二人に担当していただきましたが、新卒研修全体へのお二人の印象などはどんなものでしょうか?`}</p>
    <p><strong parentName="p">{`上村`}</strong>{`: 羨ましいなと思っちゃいますね w 自分が IT 業界で働き始めた時は特に「即 OJT」という感じでしたので、コンテンツがとても充実していて、実業務に入る時にハードルが低くなると思います。`}</p>
    <p><strong parentName="p">{`米山`}</strong>{`: 規模がとても大きい企業で充実した研修というのは分かるのですが、現在のメドレーの規模でここまで充実した研修をしているのは凄いなと感じます。去年までに入社した新卒メンバーも配属後にすごく活躍しているのを見ているので、充実した研修が活きているのではないでしょうか。`}</p>
    <p>{`自分が QA エンジニアのキャリアを始めた時にも、複数の研修を受けたのですが、今でもちゃんと記憶に残っている研修というのがあるので、講師側としてはそういった研修にできるようにしていきたいですね。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/e332baaaed95304d43b4d31f251d9941/eea4a/0804_003.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEAv/EABUBAQEAAAAAAAAAAAAAAAAAAAEA/9oADAMBAAIQAxAAAAHNqLxSMC//xAAcEAABAwUAAAAAAAAAAAAAAAACAQMSBAUyM0P/2gAIAQEAAQUCpzmhpEGgAm7fr6Ll/8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQMBAT8Bp//EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAECAQE/AYf/xAAbEAEAAgIDAAAAAAAAAAAAAAABAAIQcRFRsf/aAAgBAQAGPwJO5x5BvUXUtvDP/8QAGhABAAMBAQEAAAAAAAAAAAAAAQARITFB0f/aAAgBAQABPyHMgVbEXTQ9RBN6toS+t+ZR5JkzC5//2gAMAwEAAgADAAAAEAD/AP/EABYRAQEBAAAAAAAAAAAAAAAAAAARMf/aAAgBAwEBPxDCV//EABURAQEAAAAAAAAAAAAAAAAAABEA/9oACAECAQE/ECs3/8QAGxABAAMBAAMAAAAAAAAAAAAAAQARITFBkaH/2gAIAQEAAT8QtK1YKG+rDoWYGH34h5WwPqZgxLrahrulm1GsEMhhwn//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "0804 003",
            "title": "0804 003",
            "src": "/static/e332baaaed95304d43b4d31f251d9941/e5166/0804_003.jpg",
            "srcSet": ["/static/e332baaaed95304d43b4d31f251d9941/f93b5/0804_003.jpg 300w", "/static/e332baaaed95304d43b4d31f251d9941/b4294/0804_003.jpg 600w", "/static/e332baaaed95304d43b4d31f251d9941/e5166/0804_003.jpg 1200w", "/static/e332baaaed95304d43b4d31f251d9941/eea4a/0804_003.jpg 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`来年に向けて`}</h2>
    <p><strong parentName="p">{`山田`}</strong>{`: これからの話もちょうど出てきたところなので、来年の QA 講座はどのようにバージョンアップしていきたいというアイディアはありますか?`}</p>
    <p><strong parentName="p">{`上村`}</strong>{`: 今回のように 90 分も良いのですが、`}<strong parentName="p">{`できれば 1 日ワークショップをやってみたい`}</strong>{`なと考えていました。それだけ時間があれば他にもやりたいことが盛り込めるなと思います。それだけの時間が確保できるかが課題なのですが…。あとは来年はできれば対面で実施できれば良いなと思います。`}</p>
    <p><strong parentName="p">{`米山`}</strong>{`: 新卒メンバーは私達の研修の後に「開発実践研修」で実際にプロダクトを作るフェイズがあるので、来年はその開発実践研修の内容とリンクさせて、`}<strong parentName="p">{`すぐに活用できるような実践的な話もできたらと考えています`}</strong>{`。`}</p>
    <p><strong parentName="p">{`上村`}</strong>{`: 確かに何かアプリを触ってもらって、テスト観点はどういったものがあるかなんかを考えてもらうなども良いですね。`}</p>
    <p>{`具体的なテスト技法とか考え方なんかは検索すれば出てくるものですが、実際にそれらをどう使うか、いつ使うかという点が難しいところなので、きちんと伝えていきたいです。`}</p>
    <p><strong parentName="p">{`米山`}</strong>{`: あとは中長期的な目標としては、こうした研修をしていくことによって`}<strong parentName="p">{`社内で QA エンジニアを志望する方が出てくるような講座`}</strong>{`ができれば良いなという願望があります w`}</p>
    <p><strong parentName="p">{`山田`}</strong>{`: なるほど、社内スカウトができるように充実した研修にするということですね w`}</p>
    <p>{`本日はありがとうございました。`}</p>
    <h2>{`おわりに`}</h2>
    <p>{`今年初めての試みである QA 新卒講座をどのように作っていったのかを、お送りしました。`}</p>
    <p>{`メドレーでは QA エンジニアだけが品質に責任を持つというわけではなく、開発に携わる全員が責任を持っていくというスタイルでプロダクト開発をしているため、そうした姿勢を新卒メンバーに伝える上でも今回の講座はとても有用だったと考えています。`}</p>
    <p>{`このような開発スタイルで日々プロダクトを作っているメンバーに興味が出て話を聞きたい! という方はぜひカジュアルにお話できればと思います。`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/jobs"
      }}>{`https://www.medley.jp/jobs`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      